import { useRegistrationDispatch } from "./registration-context";
import React from "react";
import { TextInput } from "./text-input";
import { reach } from "yup";
import { iceSchema } from "./ice";
import { formatPhoneNumber } from "./shared/format-phone-number";

type Props = {
  validateAll?: boolean;
};

const IceForm = (props: Props) => {
  const { updateIce } = useRegistrationDispatch();

  return (
    <>
      <TextInput
        type="text"
        validateOnLoad={props.validateAll}
        placeholder="Namn"
        autoComplete="name"
        validate={(value) => reach(iceSchema, "name").isValidSync(value)}
        onChange={(event) => {
          updateIce({
            name: event.target.value,
          });
        }}
        required
      />
      <TextInput
        type="tel"
        validateOnLoad={props.validateAll}
        autoComplete="tel"
        placeholder="Telefonnummer"
        validate={(value) => reach(iceSchema, "phoneNumber").isValidSync(value)}
        onChange={(event) =>
          updateIce({
            phoneNumber: event.target.value,
            formattedPhoneNumber: formatPhoneNumber(event.target.value),
          })
        }
        required
      />
    </>
  );
};

export { IceForm };
