import { useRegistrationDispatch } from "./registration-context";
import React from "react";
import { reach } from "yup";
import { TextInput } from "./text-input";
import { addressSchema } from "./adress";

type Props = {
  validateAll?: boolean;
};

const AddressForm = (props: Props) => {
  const { updateAddress } = useRegistrationDispatch();

  return (
    <>
      <TextInput
        type="text"
        validateOnLoad={props.validateAll}
        placeholder="Adress"
        autoComplete="street-address"
        required
        validate={(value) => reach(addressSchema, "street").isValidSync(value)}
        onChange={(event) =>
          updateAddress({
            street: event.target.value,
          })
        }
      />
      <TextInput
        type="text"
        validateOnLoad={props.validateAll}
        placeholder="Postnummer"
        autoComplete="postal-code"
        required
        validate={(value) =>
          reach(addressSchema, "postalCode").isValidSync(value)
        }
        onChange={(event) =>
          updateAddress({
            postalCode: event.target.value,
          })
        }
      />
      <TextInput
        type="text"
        validateOnLoad={props.validateAll}
        placeholder="Postort"
        autoComplete="address-level1"
        required
        validate={(value) => reach(addressSchema, "city").isValidSync(value)}
        onChange={(event) =>
          updateAddress({
            city: event.target.value,
          })
        }
      />
    </>
  );
};

export { AddressForm };
