/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const Spacer = (props: { size: number }) => {
  return (
    <div
      css={css`
        background: transparent;
        width: ${props.size}px;
        height: ${props.size}px;
      `}
    />
  );
};

export { Spacer };
