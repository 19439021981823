import { string } from "yup";
import { arrayFromNumber } from "./array-from-number";

const DEFAULT_ID_LENGTH = 20;

const generateId = (options?: { prefix?: string; length?: number }) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const id = arrayFromNumber(options?.length ?? DEFAULT_ID_LENGTH)
    .map((_) => chars.charAt(Math.floor(Math.random() * chars.length)))
    .join("");

  return `${options?.prefix ?? ""}${id}`;
};

const idSchema = string().required();

export { generateId, idSchema };
