/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
import Select, { ValueType } from "react-select";

import {
  useRegistrationDispatch,
  useRegistrationState,
} from "./registration-context";
import { Spacer } from "./shared/spacer";
import { TextInput } from "./text-input";
import { memberSchema } from "./member";
import { reach } from "yup";
import { formatPhoneNumber } from "./shared/format-phone-number";

type Props = {
  readonly memberId: string;
  readonly index: number;
  readonly validateAll?: boolean;
};

const MemberForm = (props: Props) => {
  const { members } = useRegistrationState();
  const { updateMember, removeMember } = useRegistrationDispatch();

  console.log("MEMBER", members, props.memberId);
  const member = members.find((item) => item._id === props.memberId);
  if (!member) return null;

  return (
    <React.Fragment>
      {members.length > 1 ? (
        <React.Fragment>
          <div
            css={css`
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div style={{ fontWeight: 700 }}>
              {`Medlem ${props.index + 1})`}
            </div>
            <div
              style={{ color: "yellow", fontWeight: 700 }}
              onClick={() => removeMember(props.memberId)}
            >
              X
            </div>{" "}
          </div>
          <Spacer size={10} />
        </React.Fragment>
      ) : null}
      <TextInput
        type="text"
        validateOnLoad={props.validateAll}
        placeholder="Personnummer"
        required
        validate={(value) => {
          const valid = reach(
            memberSchema,
            "personalIdentityNumber"
          ).isValidSync(value);
          console.log("pid is valid", valid);
          return valid ? true : "giltigt personnummer krävs";
        }}
        onChange={(event) =>
          updateMember(member._id, {
            personalIdentityNumber: event.target.value,
          })
        }
      />
      <TextInput
        type="text"
        placeholder="Förnamn"
        validateOnLoad={props.validateAll}
        autoComplete="given-name"
        required
        validate={(value) =>
          reach(memberSchema, "firstName").isValidSync(value)
        }
        onChange={(event) =>
          updateMember(member._id, {
            firstName: event.target.value,
          })
        }
      />
      <TextInput
        type="text"
        placeholder="Efternamn"
        validateOnLoad={props.validateAll}
        required
        autoComplete="family-name"
        validate={(value) => reach(memberSchema, "lastName").isValidSync(value)}
        onChange={(event) =>
          updateMember(member._id, {
            lastName: event.target.value,
          })
        }
      />
      <TextInput
        type="email"
        validateOnLoad={props.validateAll}
        autoComplete="email"
        placeholder="Epost"
        required
        validate={(value) => reach(memberSchema, "email").isValidSync(value)}
        onChange={(event) =>
          updateMember(member._id, {
            email: event.target.value,
          })
        }
      />
      <TextInput
        type="tel"
        validateOnLoad={props.validateAll}
        autoComplete="tel"
        required
        placeholder="Telefonnummer"
        validate={(value) =>
          reach(memberSchema, "phoneNumber").isValidSync(value)
        }
        onChange={(event) =>
          updateMember(member._id, {
            phoneNumber: event.target.value,
            formattedPhoneNumber: formatPhoneNumber(event.target.value),
          })
        }
      />
      <Spacer size={20} />
      <Select
        className="memsel-container"
        classNamePrefix="memsel"
        options={[
          "Musiklek",
          "Scout",
          "Småbarnsmusik",
          "Sould Children",
          "Sould Kids",
          "Söndagsskola Guldgruvan",
          "Söndagsskola Underground",
          "Tonår",
        ].map((groupName) => ({
          value: groupName,
          label: groupName,
        }))}
        isMulti={true}
        isClearable={false}
        onChange={(selected: ValueType<{ value: string; label: string }>) => {
          const _selected = Array.isArray(selected)
            ? (selected as typeof selected[])
            : [];
          console.log("SELECTED", selected);
          updateMember(member._id, {
            groups: _selected
              .filter(Boolean)
              .map((option) => ((option! as unknown) as any).value),
          });
        }}
        menuPortalTarget={document.getElementById("menu-portal")}
        placeholder="Grupper..."
      />
      {/* <select
        multiple
        value={member.groups ?? []}
        onChange={(event) => {
          const options = Array.from(event.target.options).filter(
            (opt) => opt.selected
          );
          console.log(
            "GROUPS",
            options.map((opt) => opt.value)
          );
          updateMember(member._id, {
            groups: options.map((option) => option.value),
          });
        }}
      >
        <option value="After School">
        <option value="Blöjgung">
        <option value="Högstadiehäng">
        <option value="Jönköping Sould Children"
          Jönköping Sould Children
        </option>
        <option value="Jönköping Sould Kids">
        <option value="Musiklek">
        <option value="Scouterna">
        <option value="Tonår">
      </select> */}
    </React.Fragment>
  );
};

export { MemberForm };
