import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { RegistrationProvider } from "./registration-context";
import { Global, css } from "@emotion/core";
import { Spacer } from "./shared/spacer";
import headerImageUrl from "./assets/equmenia-vit-ik.png";
import { App } from "./app";

ReactDOM.render(
  <React.StrictMode>
    <RegistrationProvider>
      <Global
        styles={css`
          html,
          body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
          }
          div,
          input,
          textarea,
          body {
            width: auto;
            box-sizing: border-box;
          }

          body {
            font-family: "Open Sans", Arial, Helvetica;
            font-size: 14px;
            background-color: rgb(103, 45, 103);
            color: #fff;
          }
          input {
            display: block;
            padding: 8px 10px;
            border-radius: 3px;
            color: #fff;
            font-size: 14px;
            border-style: solid;
            border: 1px solid transparent;
            border-bottom-color: rgb(173, 77, 173);
            background-color: #8a3d8a;
            &:focus {
              border: 1px solid yellow;
              outline: 0;
            }

            ::selection {
              background-color: orange;
              color: #fff;
            }

            ::placeholder {
              color: white;
              opacity: 0.6;
            }
          }

          a {
            color: yellow;
            &:visited,
            &:active {
              color: yellow;
            }
          }
          .memsel-container {
            .memsel__control,
            .memsel__control--is-focused {
              & .memsel__placeholder {
                color: #fff;
                opacity: 0.4;
                padding-left: 5px;
              }
              cursor: pointer;
              background: rgb(138, 61, 138);
              color: #fff;
              border: none;
              border-bottom: 1px solid rgb(173, 77, 173);
              & > .memsel__value-container {
                background: transparent;
                padding: 6px;
                & > .memsel__multi-value {
                  background-color: rgb(173, 77, 173);
                  & > .memsel__multi-value__label {
                    color: #fff;
                  }
                }
              }
              & > .memsel__indicators {
                & > .memsel__indicator-separator {
                  background-color: rgb(173, 77, 173);
                }

                & > .memsel__clear-indicator,
                & > .memsel__dropdown-indicator {
                  color: hsl(300, 38%, 59%);

                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
            .memsel__control:hover,
            .memsel__control--is-focused {
              border-color: yellow;
              box-shadow: 0 0 0 1px yellow;
            }
          }
          .memsel__menu {
            background-color: transparent;
            .memsel__menu-list {
              border-radius: 5px;
              background: rgb(138, 61, 138);
            }
            .memsel__option {
              background: rgb(138, 61, 138);
              cursor: pointer;
              &:hover {
                background: rgba(138, 61, 138, 0.5);
              }
            }
            .memsel__option--is-focused {
              background: rgba(138, 61, 138, 0.5);
            }
            .memsel__option--is-selected {
              background: rgba(138, 61, 138, 0.5);
            }

            .memsel__menu-notice {
              color: rgb(173, 77, 173) !important;
            }
          }
        `}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
          `}
        >
          <img
            src={headerImageUrl}
            alt="Equmenia (Immanuelskyrkan)"
            style={{
              display: "block",
              padding: 20,
              maxWidth: 300,
              minWidth: 272,
              objectFit: "contain",
            }}
          />
          <Spacer size={20} />
          <App />
          <Spacer size={20} />
        </div>
      </div>
    </RegistrationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
