import { string, object } from "yup";
import { generateId, idSchema } from "./shared/generate-id";

type Address = {
  readonly _id: string;
  readonly street: string;
  readonly postalCode: string;
  readonly city: string;
};

const emptyAddress = (id?: string): Address => ({
  _id: id || generateId(),
  street: "",
  postalCode: "",
  city: "",
});

const addressSchema = object<Address>().shape({
  _id: idSchema,
  street: string().required("adress krävs"),
  postalCode: string().required("postnummer krävs"),
  city: string().required("postort krävs"),
});

export { addressSchema, emptyAddress };
export type { Address };
