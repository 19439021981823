/** @jsx jsx */
import React, { useState } from "react";
import { jsx } from "@emotion/core";

import {
  useRegistrationState,
  useRegistrationDispatch,
  registrationSchema,
} from "./registration-context";
import { MemberForm } from "./member-form";
import { AddressForm } from "./address-form";
import { IceForm } from "./ice-form";
import { FormSection } from "./form-section";
import { Spacer } from "./shared/spacer";
import { Button } from "./button";

type Props = {
  onSuccess: () => void;
};

const MembershipForm = (props: Props) => {
  const state = useRegistrationState();
  const [processing, setProcessing] = useState(false);
  const { addMember, toggleAcceptTerms } = useRegistrationDispatch();
  const [, setValidateAll] = useState(false);
  const membersArray = Array.from(state.members.values());
  console.log("members", state.members, membersArray);
  const disabled = !registrationSchema.isValidSync(state);
  return (
    <React.Fragment>
      <FormSection title={membersArray.length > 1 ? "Medlemmar" : "Medlem"}>
        {membersArray.map((m, index) => {
          console.log("member", m);
          return (
            <React.Fragment key={m._id}>
              <MemberForm key={m._id} memberId={m._id} index={index} />
              <br key={"br1" + m._id} />
              <br key={"br2" + m._id} />
            </React.Fragment>
          );
        })}
        <Button
          title="Lägg till ytterligare familjemedlem"
          onClick={() => addMember()}
        />
      </FormSection>
      <Spacer size={20} />

      <FormSection title="Adress">
        <AddressForm />
      </FormSection>
      <Spacer size={20} />

      <FormSection title="Närmast anhörig (ICE)">
        <IceForm />
      </FormSection>
      <Spacer size={20} />

      <FormSection title={"Registrera"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <input
            type="checkbox"
            id="accept-terms"
            name="accept-terms"
            defaultChecked={state.termsAccepted}
            value="accepterat-villkor"
            onChange={toggleAcceptTerms}
            style={{
              width: 20,
              height: 20,
            }}
          />
          <Spacer size={20} />
          <label htmlFor="accept-terms">
            Genom att registrera medlemskap godkänner jag även att mina
            personuppgifter sparas i Equmenias medlemssystem.
          </label>
        </div>
        <Spacer size={20} />
        <Button
          title={
            disabled
              ? "Fyll första i alla fält"
              : `Registrera ${
                  membersArray.length > 1
                    ? ` familj (${membersArray.length} medlemmar)`
                    : ""
                } `
          }
          disabled={disabled || processing}
          onClick={async () => {
            setProcessing(true);
            // Validate
            const valid = registrationSchema.isValidSync(state);
            if (!valid) {
              alert("Du måste fylla i alla fält korrekt först");
              setValidateAll(true);
              setProcessing(false);
              return;
            }

            // Register
            const response = await fetch(
              `${process.env.REACT_APP_API || "/api"}/register`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(state),
              }
            );

            setProcessing(false);
            if (response.status >= 200 && response.status < 300) {
              // All is well
              props.onSuccess();
            } else {
              console.log(response.status);
              alert(
                "Vi verkar ha en fnurra på tråden för tillfället, försök igen om en liten stund 😢 (Har du prövat många gånger meddela oss gärna på equmenia@immanuelskyrkan.com)"
              );
            }
          }}
        />
        <Spacer size={40} />
        <div
          style={{
            fontSize: 12,
            color: "#ddd",
            padding: "0 5px",
            textAlign: "center",
          }}
        >
          Equmenia i Immanuelskyrkan följer dataskyddsförordningen GDPR. Vi
          sparar personuppgifter i Equmenias nationella medlemsregister och i
          Jönköpings kommuns närvarosystem. Uppgifterna lagras så länge det
          finns laglig grund. Du har rätt att få ett utdrag på vilka uppgifter
          som registrerats. Vill du få dina uppgifter ändrade eller raderade kan
          du kontakta oss via 
          <a
            href="mailto:equmenia@immanuelskyrkan.com"
            title="Equmenia Immanuelskyrkan"
          >
            equmenia@immanuelskyrkan.com
          </a>
          .
        </div>
      </FormSection>
    </React.Fragment>
  );
};

export { MembershipForm };
