import { generateId, idSchema } from "./shared/generate-id";
import { object, string, array } from "yup";

type Member = {
  _id: string;
  personalIdentityNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  formattedPhoneNumber: string;
  groups: string[];
};

const emptyMember = (id?: string): Member => ({
  _id: id ?? generateId({ prefix: "member_" }),
  firstName: "",
  lastName: "",
  email: "",
  personalIdentityNumber: "",
  phoneNumber: "",
  formattedPhoneNumber: "",
  groups: [],
});

const memberSchema = object<Member>().shape({
  _id: idSchema,
  firstName: string().min(2).required(),
  lastName: string().min(2).required(),
  email: string().email().required(),
  personalIdentityNumber: string()
    .matches(/^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/)
    .required(),
  phoneNumber: string().min(8).required(),
  formattedPhoneNumber: string().min(8).required(),
  groups: array().of(string().required()).defined(),
});

export { emptyMember, memberSchema };
export type { Member };
