/** @jsx jsx */
import React, { useState } from "react";
import { jsx, css } from "@emotion/core";

type Props = {
  initialValue?: string;
  type: "text" | "tel" | "email";
  placeholder: string;
  autoComplete?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: string) => boolean | string;
  required?: boolean;
  validateOnLoad?: boolean;
};

const TextInput = (props: Props) => {
  const [value, setValue] = useState(props.initialValue);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    const valid =
      props.validate && value
        ? props.validate(value)
        : !(props.required && !value);
    console.log({ valid, req: props.required });
    if (valid !== true) {
      setErrorMessage(typeof valid === "string" ? `* ${valid}` : "* krävs");
    } else setErrorMessage("");
  };

  if (props.validateOnLoad) {
    validate();
  }

  return (
    <React.Fragment>
      <input
        type={props.type}
        value={value}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        onChange={(event) => {
          setValue(event.target.value);
          props.onChange(event);
        }}
        onBlur={validate}
      />
      {errorMessage ? (
        <div
          css={css`
            color: yellow;
            font-weight: 400;
            padding: 10px;
            margin-bottom: 10px;
          `}
        >
          {errorMessage}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export { TextInput };
