import { useState } from "react";
import { ThankYou } from "./thank-you";
import React from "react";
import { MembershipForm } from "./membership-form";

const App = () => {
  const [page, setPage] = useState<"REGISTER" | "THANK_YOU">("REGISTER");
  switch (page) {
    case "THANK_YOU":
      return <ThankYou />;
    default:
      return <MembershipForm onSuccess={() => setPage("THANK_YOU")} />;
  }
};
export { App };
