const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return "";
  let output: string = phoneNumber;

  if (output.startsWith("0046")) {
    output = output.replace("0046", "+46");
  }
  if (output.startsWith("007")) {
    output = output.replace("007", "+467");
  }
  if (output.startsWith("07")) {
    output = output.replace("07", "+467");
  }
  if (output.startsWith("007")) {
    output = output.replace("007", "+467");
  }
  if (output.startsWith("07")) {
    output = output.replace("07", "+467");
  }
  if (output.startsWith("00")) {
    output = output.replace("00", "+467");
  }
  if (output.startsWith("0")) {
    output = output.replace("0", "+46");
  }
  output = output.replace(/\s+/g, ""); // replace all spaces with nothing
  return output;
};

export { formatPhoneNumber };
