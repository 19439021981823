/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
import { Spacer } from "./shared/spacer";

type Props = {
  title?: string;
  children: React.ReactNode;
};
const FormSection = (props: Props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        max-width: 478px;
        min-width: 375px;
        flex-shrink: 1;
        padding: 20px;
        border-radius: 5px;
        background-color: rgb(138, 61, 138);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
      `}
    >
      {props.title ? <h3>{props.title}</h3> : null}
      {props.children}
      <Spacer size={20} />
    </div>
  );
};

export { FormSection };
