/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";

import { FormSection } from "./form-section";
import { Spacer } from "./shared/spacer";

const ThankYou = () => {
  return (
    <React.Fragment>
      <FormSection>
        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            width: "90%",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Spacer size={15} />
          <div style={{ fontSize: 24, fontWeight: 700 }}>
            Tack och välkommen!
          </div>
          <Spacer size={30} />
          <div style={{ fontSize: 16 }}>
            Din medlemsregistrering är klar!
            <span
              style={{ fontSize: 20, paddingLeft: 10 }}
              role="img"
              aria-label="hurray"
            >
              🙌
            </span>
          </div>
          <Spacer size={20} />
          <div style={{ fontSize: 16 }}>
            Nästa steg är att betala medlemsavgiften.
            <br />
            200 kr per person och år <br />
            eller 450 kr per familj.
            <br />
            <br />
            Den betalas bara en gång oavsett hur många grupper du deltar i.
          </div>
          <Spacer size={20} />
          <div style={{ fontSize: 16 }}>
            Betalning kan ske till:
            <br />
            Swish: <span style={{ fontWeight: 700 }}>123 182 42 67</span> <br />
            Bankgiro: <span style={{ fontWeight: 700 }}>5766-8626</span>
            <br />
            <br />
            Skriv i meddelandefältet vem eller vilka betalningen avser.
          </div>
          <Spacer size={40} />
          <div style={{ fontSize: 12, fontStyle: "italic" }}>
            Om du inte har möjlighet att betala går det bra att endast vara
            registrerad medlem.
          </div>
        </div>
      </FormSection>
      <Spacer size={10} />
    </React.Fragment>
  );
};

export { ThankYou };
