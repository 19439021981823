import { string, object } from "yup";
import { generateId, idSchema } from "./shared/generate-id";

// tslint:disable-next-line:interface-name
type Ice = {
  _id: string;
  name: string;
  phoneNumber: string;
  formattedPhoneNumber: string;
};

const emptyIce = (id?: string): Ice => ({
  _id: id || generateId({ prefix: "ice_" }),
  name: "",
  phoneNumber: "",
  formattedPhoneNumber: "",
});

const iceSchema = object<Ice>().shape({
  _id: idSchema,
  name: string().min(2, "minst två bokstäver").required("namn krävs"),
  phoneNumber: string().required("mobilnummer krävs"),
  formattedPhoneNumber: string().required("mobilnummer krävs"),
});

export { emptyIce, iceSchema };
export type { Ice };
