/** @jsx jsx */
import { jsx, css } from "@emotion/core";

type Props = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

const Button = (props: Props) => {
  return (
    <button
      css={css`
        color: white;
        padding: 10px 15px;
        font-size: 14px;
        background-color: ${props.disabled ? "#999" : "darkorange"};
        border-radius: 4px;
        ${props.disabled || `border: 1px solid rgb(179, 104, 0);`}
        ${props.disabled ||
        `box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px 0px,
          rgba(0, 0, 0, 0.05) 0px 3px 9px 0px;`}
      `}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  );
};

export { Button };
