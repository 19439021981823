import React, { useContext } from "react";
import { Member, emptyMember, memberSchema } from "./member";
import { Address, emptyAddress, addressSchema } from "./adress";
import { Ice, emptyIce, iceSchema } from "./ice";
import { generateId, idSchema } from "./shared/generate-id";
import { object, array, boolean } from "yup";

type State = {
  _id: string;
  members: Member[];
  address: Address;
  ice: Ice;
  termsAccepted: boolean;
};

const registrationSchema = object<State>().shape({
  _id: idSchema,
  members: array().of(memberSchema).defined(),
  address: addressSchema.required(),
  ice: iceSchema.required(),
  termsAccepted: boolean().oneOf([true]),
});

type Action =
  | {
      type: "ADD_MEMBER";
      memberId: string;
    }
  | {
      type: "UPDATE_MEMBER";
      memberId: string;
      member: Partial<Omit<Member, "_id">>;
    }
  | {
      type: "REMOVE_MEMBER";
      memberId: string;
    }
  | {
      type: "UPDATE_ICE";
      ice: Partial<Ice>;
    }
  | {
      type: "UPDATE_ADRESS";
      address: Partial<Address>;
    }
  | {
      type: "TOGGLE_TERMS_ACCEPTED";
    };

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_MEMBER": {
      console.log("add member", action);
      if (state.members.some((member) => member._id === action.memberId))
        return state;
      return {
        ...state,
        members: [...state.members, emptyMember(action.memberId)],
      };
    }
    case "REMOVE_MEMBER": {
      return {
        ...state,
        members: state.members.filter(
          (member) => member._id !== action.memberId
        ),
      };
    }
    case "TOGGLE_TERMS_ACCEPTED": {
      return {
        ...state,
        termsAccepted: !state.termsAccepted,
      };
    }
    case "UPDATE_MEMBER": {
      const memberData = state.members.find(
        (member) => member._id === action.memberId
      );
      if (!memberData)
        throw new Error("Can't update member that doesn't exist");

      const updatedMember = {
        ...memberData,
        ...action.member,
        _id: action.memberId,
      };
      return {
        ...state,
        members: state.members.map((member) =>
          member._id === updatedMember._id ? updatedMember : member
        ),
      };
    }
    case "UPDATE_ADRESS": {
      return {
        ...state,
        address: {
          ...(state.address ?? emptyAddress(action.address._id)),
          ...action.address,
        },
      };
    }
    case "UPDATE_ICE": {
      return {
        ...state,
        ice: {
          ...(state.ice ?? emptyAddress(action.ice._id)),
          ...action.ice,
        },
      };
    }
    default:
      return state;
  }
};

const createInitialState = (): State => {
  const member = emptyMember();
  return {
    _id: generateId({ prefix: "registration_" }),
    address: emptyAddress(),
    ice: emptyIce(),
    members: [member],
    termsAccepted: false,
  };
};

const RegistrationStateContext = React.createContext<State | undefined>(
  undefined
);
const RegistrationDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const RegistrationProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, createInitialState());
  return (
    <RegistrationStateContext.Provider value={state}>
      <RegistrationDispatchContext.Provider value={dispatch}>
        {props.children}
      </RegistrationDispatchContext.Provider>
    </RegistrationStateContext.Provider>
  );
};

const useRegistrationState = (): State => {
  const context = useContext(RegistrationStateContext);
  if (!context) {
    throw new Error(
      "useRegistrationState must be used withing a RegistrationProvider"
    );
  }
  return context;
};
const useRegistrationDispatch = () => {
  const dispatch = useContext(RegistrationDispatchContext);
  if (!dispatch) {
    throw new Error(
      "useRegistrationDispatch must be used withing a RegistrationProvider"
    );
  }

  const updateMember = (
    memberId: string,
    member: Partial<Omit<Member, "_id">>
  ) => dispatch({ type: "UPDATE_MEMBER", member, memberId });
  const updateIce = (ice: Partial<Ice>) =>
    dispatch({ type: "UPDATE_ICE", ice });
  const updateAddress = (address: Partial<Address>) =>
    dispatch({ type: "UPDATE_ADRESS", address });
  const addMember = (memberId?: string) =>
    dispatch({
      type: "ADD_MEMBER",
      memberId: memberId ?? generateId({ prefix: "member_" }),
    });
  const removeMember = (memberId: string) =>
    dispatch({ type: "REMOVE_MEMBER", memberId });

  const acceptTermsToggle = () => dispatch({ type: "TOGGLE_TERMS_ACCEPTED" });

  return {
    addMember,
    removeMember,
    updateMember,
    updateIce,
    updateAddress,
    toggleAcceptTerms: acceptTermsToggle,
  };
};

export {
  RegistrationProvider,
  useRegistrationState,
  useRegistrationDispatch,
  registrationSchema,
};
export type { State as Registration };
